@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  overflow-x: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.banner {
  /* background-color: #133167; */
  margin-bottom: 3vh;
  padding-bottom: 10vh;
  margin-top: -2%;
}

.bannerDesc {
  width: 100%;
}

.btn {
  background-color: #133167 !important;
}

.grey {
  background-color: #CFD6E1 !important;
  border-radius: 8%;
}

.spacing {
  margin-top: 5% !important;
  margin-bottom: 5% !important;
}

.boxes {
  display: flex !important;
  flex-wrap: wrap !important;
}

#cta {
  padding-left: 4rem
}

.gap {
  padding-left: 2rem;
}

.features_desc {
  padding-left: 1rem;
  padding-right: 0.5rem;
}

.testimonials {
  background-color: #CFD6E1;
  height: 540px;
}

.spacer {
  position: relative;
  z-index: 5;
}

.mid {
  margin: auto;
  position: relative;
  top: 30%;
}

.witnessbox{
  width:94% !important;
  height: 250px !important;
  border-radius: 20px;
  box-shadow: "0px 4px 6px rgba(0, 0, 0, 0.1)"
}

@media (max-width: 1200px) {
  #leftTitle {
    margin-left: -14px !important;
    width: fit-content !important;
  }

  .left {
    width: 80%;
  }

  .right {
    margin-left: -8% !important;
    margin-top: 12% !important;
  }
}

@media (max-width: 767px) {
  .reverse {
    display: none !important;
  }

  .textInBlue {
    width: fit-content !important;
  }

  #leftTitle {
    margin-top: -7% !important;
  }

  .left {
    margin-left: -30% !important;
    width: 90%;
  }

  .right {
    margin-left: -7% !important;
    margin-top: 26% !important;
  }

  .ad {
    flex-wrap: wrap !important;
    flex-direction: column-reverse !important;
  }

  .adText {
    margin-top: -20% !important;
  }

  .join {
    text-align: center;
  }
}